<template>
  <div class="portal_login flex-center">
    <div class="login_center posi-rel">
      <!--  左侧登录区域-->
      <div class="login_center_left">
        <div class="flex-center width-100">
          <img src="../../assets/login-5.png" class="title_img" alt="" />
        </div>
        <!-- 跳转注册 -->
        <div class="title_text">
          Don't Have Account?
          <span class="pointer" @click="navTo('/register')">Sign Up Here</span>
        </div>
        <!-- email输入框 -->
        <div class="login_fill width-100">
          <div>Email</div>
          <div class="login_fill_input width-100">
            <el-input v-model="query.email" clearable> </el-input>
          </div>
        </div>
        <!-- 密码输入框 -->
        <div class="login_fill width-100">
          <div>Passward</div>
          <div class="login_fill_input width-100">
            <el-input v-model="query.password" type="password" clearable>
            </el-input>
          </div>
        </div>
        <!-- 记住密码 -->
        <div class="width-100 space-between rember_fill">
          <el-checkbox v-model="checked">Rember</el-checkbox>
          <span class="forget_password pointer" @click="navTo('/forget')"
            >Forget Passward
          </span>
        </div>
        <!-- 登录按钮 -->
        <div class="login_button">
          <div class="width-100 flex-center" @click="loginClick">LOGIN</div>
        </div>
        <!-- 分割文字 -->
        <div class="width-100 space-between separate_text">
          <div></div>
          <div>OR</div>
          <div></div>
        </div>
        <!-- 谷歌登录 -->
        <div class="width-100 flex-center guge-button">
          <div class="row pointer g_id_signin" id="g_id_signin">
            <!-- <img src="../../assets/login-6.png" alt="" />
            <span>谷歌</span> -->
          </div>

          <!--  <GoogleLogin
            :params="params"
            :renderParams="renderParams"
            :onSuccess="onSuccess"
          ></GoogleLogin> -->
        </div>
      </div>
      <!-- 右侧三个样式图片 -->
      <img
        src="../../assets/login-2.png"
        class="login_img_two posi-abs"
        alt=""
      />
      <img
        src="../../assets/login-3.png"
        class="login_img_three posi-abs"
        alt=""
      />
      <img
        src="../../assets/login-4.png"
        class="login_img_four posi-abs"
        alt=""
      />
    </div>
  </div>
</template>
<script>
import api from "../../api/index";
//引入
import GoogleLogin from "vue-google-login";
import jwt_decode from "jwt-decode";
export default {
  //注册
  components: {
    GoogleLogin,
  },

  data() {
    return {
      checked: false,
      query: {
        email: "",
        password: "",
      },
      // 559696524073-saeso3vaiufcsvskk3al9ihv77jt5m0g.apps.googleusercontent.com
      GOOGLE_CLIENT_ID: "",
      params: {
        client_id: "",
      },
      // only needed if you want to render the button with the google ui
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true,
      },
    };
  },
  destroyed() {
    window.removeEventListener("load", this.googleClick);
    localStorage.removeItem("sx");
  },
  created() {
    this.GOOGLE_CLIENT_ID = JSON.parse(
      localStorage.getItem("siteObj")
    ).google.client_id;
    this.getCookie();
    // this.params.client_id = this.GOOGLE_CLIENT_ID;
    // 使用谷歌登录的api
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    document.body.appendChild(script);
    window.addEventListener("load", this.googleClick);

      let sx = localStorage.getItem("sx");
     
      if (!sx) {
        localStorage.setItem('sx',1)
        setTimeout(() => {
          window.location.reload();
        }, 50);
      }
  },
  mounted() {},

  methods: {
    googleClick() {
      window.google.accounts.id.initialize({
        // 主要就是填写client_id
        client_id: this.GOOGLE_CLIENT_ID,
        auto_select: false,
        callback: this.handleCredentialResponse,
      });
      // 设置按钮的样式等
      window.google.accounts.id.renderButton(
        document.getElementById("g_id_signin"),
        {
          theme: "filled_blue",
          size: "large",
          // width:'320',
          type: "standard",
          text: "signin_with",
        }
      );
    },
    handleCredentialResponse(response) {
      // 获取回调响应的凭证数据 然后拿这个凭证给后台，后台jwt进行解析获取登录信息
      let info = jwt_decode(response.credential);
      info.credential = response.credential;
      api.gooleLogin(info).then((res) => {
        window.location.href = res.data.url;
      });
    },
    // 获取cookie中的值
    getCookie() {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split(";");
        for (var i = 0; i < arr.length; i++) {
          var userKey = arr[i].split("=");
          if (userKey[0].trim() == "email") {
            this.query.email = userKey[1];
          } else if (userKey[0].trim() == "password") {
            this.query.password = userKey[1];
          } else if (userKey[0].trim() == "check") {
            this.checked = Boolean(userKey[1]);
          }
        }
      }
    },
    loginClick() {
      if (!/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(this.query.email)) {
        this.$message.error("Please enter the correct email address");
        return;
      } else if (this.query.password.trim() == "") {
        this.$message.error("Please enter your password");
        return;
      }
      // 登录时判断是否选择记住密码，如果是则设置cookie不是清除cookie，然后再调用登录函数进行登录
      if (this.checked) {
        this.setCookie(this.query.email, this.query.password, 7);
      } else {
        this.clearCookie();
      }
      api.login(this.query).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "Login successful",
            type: "success",
          });
          setTimeout(() => {
            window.location.href = res.data.redirct_url;
          }, 1000);
        }
      });
    },
    // 存储cookie
    setCookie(email, password, days) {
      var curDate = new Date();
      curDate.setTime(curDate.getTime() + 24 * 60 * 60 * 1000 * days); //设置cookie存储的有效时间
      window.document.cookie =
        "email" + "=" + email + ";path=/;expires=" + curDate.toGMTString();
      window.document.cookie =
        "password" +
        "=" +
        password +
        ";path=/;expires=" +
        curDate.toGMTString();
      window.document.cookie =
        "check" + "=" + this.check + ";path=/;expires=" + curDate.toGMTString();
    },
    //如果用户不选择记住密码清除cookie
    clearCookie() {
      this.setCookie("", "", -1);
    },
    // 跳转页面
    navTo(url) {
      this.$router.push(url);
    },
  },
};
</script>
<style lang="less" scoped>
.portal_login {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/login.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .login_center {
    width: 1226px;
    height: 647px;
    margin: 0 auto;
    background: #fff;
    background-image: url("../../assets/login-1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 90px 0 30px 54px;

    .login_center_left {
      width: 388px;
      height: 100%;
      font-size: 14px;
      color: #333333;

      .title_img {
        width: 266px;
        height: 48px;
        margin-bottom: 28px;
      }

      .title_text {
        span {
          color: #ff652b;
        }
      }

      .login_fill {
        margin-top: 12px;

        .login_fill_input {
          margin-top: 2px;

          /deep/.el-input__inner:focus {
            border-color: #ffebd8;
          }
        }
      }

      .rember_fill {
        margin-top: 19px;

        .forget_password {
          color: #ff652b;
        }

        /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
          color: #ff652b;
        }

        /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
        .el-checkbox__input.is-indeterminate .el-checkbox__inner {
          background-color: #ff652b;
          border-color: #ff652b;
        }

        /deep/.el-checkbox__inner:hover {
          border-color: #fb9a67;
        }

        /deep/.el-checkbox__input.is-focus .el-checkbox__inner {
          border-color: #fb9a67;
        }
      }

      .login_button {
        margin-top: 51px;
        margin-bottom: 48px;

        div {
          height: 45px;
          background: #ff652b;
          border-color: #ff652b;
          font-size: 24px;
          color: #fff;
          border-radius: 8px;
          cursor: pointer;
        }

        div:hover {
          background: #fb9a67;
          border-color: #fb9a67;
        }
      }

      .separate_text {
        font-size: 16px;

        div:first-child,
        div:last-child {
          width: 160px;
          height: 0px;
          opacity: 0.3;
          border-bottom: 1px solid #707070;
        }
      }

      .guge-button {
        margin-top: 35px;

        // div {
        //   width: 187px;
        //   height: 47px;
        //   background: #ffffff;
        //   border-radius: 4px;
        //   border: 1px solid #707070;
        //   padding-left: 18px;
        //   font-size: 16px;

        //   img {
        //     width: 47px;
        //     height: 100%;
        //     margin-right: 13px;
        //   }
        // }

        // div:hover {
        //   border: 1px solid #ffebd8;
        // }
      }
    }

    .login_img_two {
      top: 135px;
      right: 235px;
      width: 282px;
      height: 319px;
    }

    .login_img_three {
      top: 189px;
      right: 445px;
      width: 40px;
      height: 74px;
      z-index: 2;
    }

    .login_img_four {
      top: 330px;
      right: 174px;
      width: 75px;
      height: 111px;
      z-index: 2;
    }
  }
}
</style>
